import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image";

import Head from "../components/head"
import Layout from "../components/layout"

// import blogPostsStyles from "../styles/modules/blogposts.module.scss"

export const query = graphql`
    query blogPageQuery($skip: Int!, $limit: Int!) {
        allWpPost(
            sort: {order: DESC, fields: date},
            limit: $limit
            skip: $skip
        ) {
            nodes {
                title
                excerpt
                slug
                content
                featuredImage {
                    node {
                        localFile{
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    sourceUrl
                    title
                    }
                }
            }
        }
    }
`

const Blog = ( props ) => {
    const { data } = props

    const { currentPage, numPages } = props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '' : `/${(currentPage - 1).toString()}`
    const nextPage = (currentPage + 1).toString()

    return (
        <Layout css_class="archive">
            <Head title="Blog" />
            <main>
                <div className="container">
                    <div className="archive-page-title"><span>Cooking</span></div>
                    <section>
                        <div className="posts-layout posts-layout--three-col">
                            <div className="row">
                                <ul>
                                    {data.allWpPost.nodes.map(node => {
                                        return (
                                            <li key={node.slug}>
                                                {node.featuredImage && (
                                                    <Link
                                                        to={`/blog/${node.slug}/`}
                                                    >
                                                        <Img fluid={node.featuredImage.node.localFile.childImageSharp.fluid} key={node.featuredImage.node.localFile.childImageSharp.fluid.src} />
                                                    </Link>
                                                )}
                                                <div className="posts-layout__content">
                                                    <Link
                                                        to={`/blog/${node.slug}/`}
                                                    >
                                                        <h2>{node.title}</h2>
                                                    </Link>
                                                    <div dangerouslySetInnerHTML={{ __html: node.excerpt.replace(/<a class="more-link.*/g, '') }}></div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <ul className="pagination">
                        {!isFirst && (
                            <Link 
                                className="prev"
                                to={`/blog${prevPage}/`} 
                                rel="prev"
                            >
                            Previous
                            </Link>
                        )}
                        {Array.from({ length: numPages }, (_, i) => (
                            <li
                                key={`pagination-number${i + 1}`}
                            >
                                <Link
                                    className={i + 1 === currentPage ? 'current' : ''}
                                    to={`/blog${i === 0 ? '' : '/' + (i + 1)}/`}
                                >
                                    {i + 1}
                                </Link>
                            </li>
                        ))}
                        {!isLast && (
                            <Link 
                                className="next"
                                to={`/blog/${nextPage}/`} rel="next">
                            Next
                            </Link>
                        )}
                    </ul>
                </div>
            </main>
        </Layout>
    )
}

export default Blog
